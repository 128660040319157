<template>
  <b-card>
    <customer-chart />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import CustomerChart from '../customer/CustomerChart.vue'

export default {
  components: {
    BCard,
    CustomerChart,
  },
}
</script>
