<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col
        cols="12"
        xl="3"
        class="mb-1"
      >
        <h6 class="m-0">
          Performance Penjualan Customer
        </h6>
        <small>{{ selectedSales.length ? 'Dibandingkan dengan sales lain.' : 'Tidak ada filter sales.' }} <span
          v-if="sales.length"
          class="text-primary cursor-pointer"
          @click="filterMode ? applyFilter() : filterMode = !filterMode"
        >{{ filterMode ? 'Terapkan Filter.' : 'Ubah Filter.' }}</span></small>
        <b-collapse :visible="filterMode">
          <div class="my-50">
            <v-select
              v-model="selectedSales"
              placeholder="Pilih sales"
              :options="sales"
              multiple
              label="name"
            >
              <template #option="data">
                <div class="d-flex">
                  <b-avatar
                    :text="avatarText(data.name)"
                    variant="light-info"
                    class="mr-50"
                    size="36"
                    :src="data.photo && data.photo !== 'default.png' ?
                      `${$restHTTP}/assets/img/profile-picture/${data.id}/${data.photo}` :
                      `${$restHTTP}/assets/img/profile-picture/default.png`
                    "
                  />
                  <div>
                    <span class="font-weight-bold d-block">{{ data.name }}</span>
                    <small class="d-block">{{ data.role.name }}</small>
                  </div>
                </div>
              </template>
            </v-select>
          </div>
        </b-collapse>
      </b-col>
      <b-col
        cols="12"
        xl="2"
        sm="6"
        class="mb-1 ml-auto"
      >
        <v-select
          v-model="viewMode"
          placeholder="Pilih satuan"
          :options="typeMode.value === 'Q' ? [{ label: 'Rupiah', value: 'rp'}, { label: 'Liter', value: 'lt'},] : [{ label: 'Rupiah', value: 'rp'}]"
          :clearable="false"
          @option:selected="renderChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        sm="6"
        class="mb-1"
      >
        <v-select
          v-model="typeMode"
          placeholder="Pilih tipe"
          :options="[{ label: 'Quote', value: 'Q'}, { label: 'Sales Order', value: 'SO'}, { label: 'Sales Invoice', value: 'SI'},]"
          :clearable="false"
          @option:selected="getChart()"
        />
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="12"
      >
        <date-range-picker
          v-model="date"
          :max-date="today"
          :min-date="new Date(today.getFullYear(), today.getMonth() - 12, 1)"
          :custom-range="range()"
          opens="left"
        />
      </b-col>
    </b-row>
    <b-row v-if="!notfound">
      <b-col
        cols="12"
        xl="8"
      >
        <div
          v-if="loadingChart"
          class="d-flex justify-content-center align-items-center"
          style="height: 20vh"
        >
          <b-spinner variant="primary" />
        </div>
        <chartjs-component-bar-chart
          v-else
          :key="chartData.toString()"
          :height="320"
          :data="chartData"
          :options="options"
        />
      </b-col>
      <b-col
        v-if="!loadingChart"
        cols="12"
        xl="4"
      >
        <swiper
          :options="swiperOption"
        >
          <swiper-slide>
            <small class="mb-50 text-primary">Akumulasi Penjualan Saya</small>
            <div class="d-flex flex-wrap mb-1">
              <div class="mr-1">
                <small class="text-muted">Total</small>
                <h5>{{ formatCurrency(myData.accumulation_total) }}</h5>
              </div>
              <div class="mr-1">
                <small class="text-muted">Total Liter</small>
                <h5>{{ formatNumber(myData.accumulation_total_liter) }}</h5>
              </div>
              <div v-if="permission.viewHpp">
                <small class="text-muted">Total HPP</small>
                <h5>{{ formatCurrency(myData.accumulation_total_hpp) }}</h5>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <small class="mb-50 text-primary">Akumulasi Penjualan Sales Lain</small>
            <div class="d-flex flex-wrap mb-1">
              <div class="mr-1">
                <small class="text-muted">Total</small>
                <h5>{{ formatCurrency(otherData.accumulation_total) }}</h5>
              </div>
              <div class="mr-1">
                <small class="text-muted">Total Liter</small>
                <h5>{{ formatNumber(otherData.accumulation_total_liter) }}</h5>
              </div>
              <div v-if="permission.viewHpp">
                <small class="text-muted">Total HPP</small>
                <h5>{{ formatCurrency(otherData.accumulation_total_hpp) }}</h5>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <small class="mb-50 text-primary">Akumulasi Penjualan Semua</small>
            <div class="d-flex flex-wrap mb-1">
              <div class="mr-1">
                <small class="text-muted">Total</small>
                <h5>{{ formatCurrency(globalData.accumulation_total) }}</h5>
              </div>
              <div class="mr-1">
                <small class="text-muted">Total Liter</small>
                <h5>{{ formatNumber(globalData.accumulation_total_liter) }}</h5>
              </div>
              <div v-if="permission.viewHpp">
                <small class="text-muted">Total HPP</small>
                <h5>{{ formatCurrency(globalData.accumulation_total_hpp) }}</h5>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <h6
          v-if="pieChartData.length"
          class="text-center mt-2"
        >
          Top 5 Item
        </h6>
        <vue-apex-charts
          v-if="pieChartData.length"
          height="180"
          :options="pieChartOption"
          :series="pieChartData"
        />
        <div
          v-else
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <Lottie
            class="w-50 mb-1"
            :options="{
              animationData: noresult,
            }"
          />
          <h6 class="text-muted font-weight-normal mb-1">
            Tidak ada top items 😞
          </h6>
        </div>
      </b-col>
    </b-row>
    <div
      v-else
      style="height: 400px"
    >
      <h6 class="text-muted">
        Tidak ada data
      </h6>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCollapse, VBToggle, BSpinner, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DateRangePicker from '@/components/input/DateRangePicker.vue'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import { formatCurrency, avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Lottie from 'vue-lottie'
import noresult from '@/assets/images/lottie/noresult.json'
import ChartjsComponentBarChart from '../../components/chart/ChartjsComponentBarChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BCollapse,
    BSpinner,
    BAvatar,
    ChartjsComponentBarChart,
    vSelect,
    DateRangePicker,
    VueApexCharts,
    Swiper,
    SwiperSlide,
    Lottie,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    customerId: {
      type: String,
      required: true,
      default: '',
    },
    sales: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      noresult,
      formatCurrency,
      avatarText,
      loadingChart: true,
      notfound: false,
      viewMode: { label: 'Rupiah', value: 'rp' },
      typeMode: { label: 'Quote', value: 'Q' },
      myData: '',
      otherData: '',
      globalData: '',
      selectedSales: [],
      swiperOption: {
        speed: 800,
        slidesPerView: 1,
        mousewheel: true,
        spaceBetween: 30,
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        loop: false,
        centeredSlides: true,
      },
      chartData: {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [
          {
            label: 'Total HPP',
            data: [],
            borderColor: '#17a2b8',
            type: 'line',
            fill: true,
            spanGaps: true,
          },
          {
            label: 'Penjualan Saya',
            data: [],
            backgroundColor: '#6DAB3C',
            borderColor: 'transparent',
            stack: 'Stack 1',
            barBottom: true,
          },
          {
            label: 'Penjualan Sales Lain',
            data: [],
            backgroundColor: 'rgba(109, 171, 60, 0.4)',
            borderColor: 'transparent',
            stack: 'Stack 1',
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        interaction: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
              stacked: true,
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                min: 0,
                fontColor: this.chartColors.labelColor,
              },
              stacked: true,
            },
          ],
        },
      },
      date: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1).setHours(0, 0, 0, 0),
        endDate: new Date().setHours(0, 0, 0, 0),
      },
      filterMode: false,
      today: new Date(),
      pieChart: {
        chart: {
          width: '100%',
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'right',
          labels: {
            colors: $themeColors.secondary,
          },
          formatter: name => (name.length > 12 ? `${name.substring(0, 12)}...` : name),
        },
        comparedResult: [2, -3, 8],
        labels: [],
        stroke: { width: 0 },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.info, $themeColors.danger, '#6256EE', $themeColors.secondary],
        grid: {
          padding: {
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      pieChartData: [],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
    }),
    pieChartOption() {
      const viewMode = this.viewMode.value
      const that = this
      return {
        ...this.pieChart,
        tooltip: {
          y: {
            formatter(value, { dataPointIndex }) {
              /* eslint-disable camelcase */
              const { total_liter } = that.globalData.top_items[dataPointIndex]
              const { total } = that.globalData.top_items_liter[dataPointIndex]
              return viewMode === 'rp' ? `${formatCurrency(value)} (${total_liter} L)` : `${value} L (${formatCurrency(total)})`
              /* eslint-enable camelcase */
            },
          },
        },
      }
    },
  },
  watch: {
    date: {
      handler() {
        this.getChart()
      },
    },
  },
  created() {
    if (this.sales) {
      this.selectedSales = this.sales
    }

    this.getChart()
  },
  methods: {
    async getChart() {
      this.loadingChart = true

      if (this.typeMode.value !== 'Q') {
        this.viewMode = { label: 'Rupiah', value: 'rp' }
      }

      if (!this.globalSale) {
        await this.$store.dispatch('getPerformancePenjualan', {
          customer_id: this.customerId,
          date: {
            date_min: new Date(this.date.startDate).toISOString().slice(0, 10),
            date_max: new Date(this.date.endDate).toISOString().slice(0, 10),
          },
          data_source: this.typeMode.value,
          ref_db: this.$store.state.reference.selectedDB.id,
        }).then(globalSale => {
          this.globalData = globalSale
        })
      }

      this.$store.dispatch('getPerformancePenjualan', {
        customer_id: this.customerId,
        date: {
          date_min: new Date(this.date.startDate).toISOString().slice(0, 10),
          date_max: new Date(this.date.endDate).toISOString().slice(0, 10),
        },
        user_id: this.$store.state.user.currentUser.user.id,
        data_source: this.typeMode.value,
        ref_db: this.$store.state.reference.selectedDB.id,
      }).then(async mySale => {
        if (this.selectedSales.length) {
          await this.$store.dispatch('getPerformancePenjualan', {
            customer_id: this.customerId,
            date: {
              date_min: new Date(this.date.startDate).toISOString().slice(0, 10),
              date_max: new Date(this.date.endDate).toISOString().slice(0, 10),
            },
            user_id: this.selectedSales.length ? this.selectedSales.map(el => el.id) : null,
            data_source: this.typeMode.value,
            ref_db: this.$store.state.reference.selectedDB.id,
          }).then(otherSales => {
            this.otherData = {
              ...otherSales,
              detail_performance: otherSales.detail_performance,
            }
          })
        }

        this.myData = {
          ...mySale,
          detail_performance: mySale.detail_performance,
        }

        this.renderChart()
      })
    },
    renderChart() {
      this.resetChart()
      this.loadingChart = true

      const labels = []

      if (this.myData.detail_performance && this.myData.detail_performance.length) {
        this.myData.detail_performance.forEach(data => {
          if (!labels.some(el => el === this.formatMonth(data.date))) {
            labels.push(this.formatMonth(data.date))
          }
        })
      }

      if (this.otherData.detail_performance && this.otherData.detail_performance.length) {
        this.otherData.detail_performance.forEach(data => {
          if (!labels.some(el => el === this.formatMonth(data.date))) {
            labels.push(this.formatMonth(data.date))
          }
        })
      }

      labels.forEach((el, index) => {
        this.chartData.labels[index] = el
      })

      if (this.myData.detail_performance && this.myData.detail_performance.length) {
        this.myData.detail_performance.forEach(data => {
          const index = this.chartData.labels.findIndex(el => el === this.formatMonth(data.date))
          this.chartData.datasets[1].data[index] = this.viewMode.value === 'rp' ? data.total : this.formatNumber(data.total_liter)
        })
      } else {
        this.chartData.datasets[1].data = []
        this.chartData.datasets[0].data = []
      }

      if (this.otherData.detail_performance && this.otherData.detail_performance.length) {
        this.otherData.detail_performance.forEach(data => {
          const index = this.chartData.labels.findIndex(el => el === this.formatMonth(data.date))
          this.chartData.datasets[2].data[index] = this.viewMode.value === 'rp' ? data.total : this.formatNumber(data.total_liter)
        })
      } else {
        this.chartData.datasets[2].data = []
      }

      if (!this.myData.detail_performance && !this.otherData.detail_performance && !this.myData.detail_performance.length && !this.otherData.detail_performance.length) {
        this.notfound = true
      }

      if (this.globalData.top_items || this.globalData.top_items_liter) {
        this.globalData.detail_performance.forEach(data => {
          const index = this.chartData.labels.findIndex(el => el === this.formatMonth(data.date))
          // eslint-disable-next-line no-nested-ternary
          this.chartData.datasets[0].data[index] = this.permission.viewHpp ? this.viewMode.value === 'rp' ? data.total_hpp : 0 : null
        })
        const dataSource = this.globalData[this.viewMode.value === 'rp' ? 'top_items' : 'top_items_liter']

        this.pieChartOption.labels = dataSource.length ? dataSource
          .filter(data => (this.viewMode.value === 'rp' ? data.total > 0 : data.total_liter > 0))
          .map(data => data.item_code) : []
        this.pieChartData = dataSource.length ? dataSource
          .filter(data => (this.viewMode.value === 'rp' ? data.total > 0 : data.total_liter > 0))
          .map(data => (this.viewMode.value === 'rp' ? data.total ?? 0 : data.total_liter ?? 0)) : []
      }

      setTimeout(() => {
        this.loadingChart = false
      }, 500)
    },
    resetChart() {
      this.notfound = false
      this.chartData.datasets[0].data = []
      this.chartData.datasets[1].data = []
      this.chartData.datasets[2].data = []
      this.chartData.labels = ['', '', '', '', '', '', '', '', '', '', '', '', '']
      this.pieChartOption.labels = []
      this.pieChartData = []
    },
    range() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayEnd = new Date()
      todayEnd.setHours(11, 59, 59, 999)
      const yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0)
      const yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(11, 59, 59, 999)
      /* eslint-disable quote-props */
      return {
        '3 Bulan Terakhir': [new Date(today.getFullYear(), today.getMonth() - 3, 1), today],
        '6 Bulan Terakhir': [new Date(today.getFullYear(), today.getMonth() - 6, 1), today],
        '1 Tahun Terakhir': [new Date(today.getFullYear(), today.getMonth() - 12, 1), today],
        'Tahun ini': [new Date(today.getFullYear(), 0, 1), today],
      }
    },
    chartColors() {
      return {
        primaryColorShade: '#6DAB3C',
        primaryLighteColorShade: 'rgba(109, 171, 60, 0.4)',
        secondaryColorShade: '#ff9f43',
        secondaryLightColorShade: 'rgba(255,159,67, 0.4)',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      }
    },
    applyFilter() {
      this.getChart()
      this.filterMode = false
    },
    formatNumber(data) {
      if (data) return Math.ceil(data * 100) / 100
      return 0
    },
    formatMonth(date) {
      const parsedDate = new Date(Date.parse(date))

      return moment(parsedDate).locale('id').format('MM/YY')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
